<script>
import { defineComponent } from "vue";
import IconX from "@/components/icons/iconX.vue";

export default defineComponent({
  name: "BannerDownloadApp",
  components: { IconX },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    close() {
      this.show = false;
    },
    openAppStore() {
      if (this.isIphone()) {
        window.open(
          "https://apps.apple.com/us/app/flixlatino/id1113757930",
          "_blank"
        );
      } else {
        window.open(
          "https://play.google.com/store/apps/details?id=com.trespixels.flixlatino&hl=es&pli=1",
          "_blank"
        );
      }
    },
  },
});
</script>

<template>
  <div class="banner-app" v-if="show">
    <div class="floating">
      <div class="cont">
        <div class="column side-left">
          <icon-x class="close-icon" @click="close" />
          <div class="banner-logo"></div>
        </div>
        <div class="column middle">
          <p class="banner-title">{{ $t("banner.title") }}</p>
          <p class="banner-subtitle">{{ $t("banner.subtitle") }}</p>
          <button class="btn btn-light text-nowrap" @click="openAppStore">
            {{ $t("banner.button") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner-app {
  height: 100px;
  position: relative;
  .close-icon {
    margin-top: 15px;
  }
  .banner-logo {
    float: right;
    width: 50px;
    height: 50px;
    background: url("/img/square-logo.png") no-repeat;
    background-size: cover;
    border-radius: 10px;
  }
  .floating {
    background: linear-gradient(90deg, #6d5dcf, #d51f27);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    .cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .column {
      padding: 10px;
    }
    .side-left {
      flex: 1;
    }

    .middle {
      flex: 3;
      padding-left: 0;
      .btn {
        font-size: 0.8rem;
        position: absolute;
        top: 15px;
        right: 5px;
        padding: 0;
        border: 5px solid #fff;
        margin: 0px;
      }
      .banner-title {
        font-size: 1rem;
        font-weight: 600;
        width: 80%;
        margin: 0;
      }
      .banner-subtitle {
        font-size: 0.7rem;
        color: #fff;
        width: 80%;
        margin: 0;
      }
    }
  }
}
</style>
