<template>
  <div class="container-fluid footer">
    <div
      class="links-container d-flex flex-column flex-md-row justify-content-center justify-space-around"
    >
      <div>
        <flix-latino-logo></flix-latino-logo>
      </div>
      <div>
        <ul v-if="isLoggedIn">
          <li>
            <strong>
              <router-link class="text-white" :to="$t('routes.search')">
                {{ $t("footer.search") }}
              </router-link>
            </strong>
          </li>
          <li>
            <router-link class="text-white" :to="$t('routes.browse_movies')">
              {{ $t("footer.movies") }}
            </router-link>
          </li>
          <li>
            <a
              class="text-white"
              title="FlixLatino Blog"
              :href="`https://flixlatino.com/blog/${
                $i18n.locale !== undefined ? $i18n.locale : 'es'
              }`"
            >
              {{ $t("footer.news") }}
            </a>
          </li>
        </ul>
        <ul v-else>
          <li>
            <strong>
              <router-link class="text-white" :to="$t('routes.discover')">
                {{ $t("footer.discover") }}
              </router-link>
            </strong>
          </li>
          <li>
            <router-link class="text-white" :to="$t('routes.browse')">
              {{ $t("footer.movies") }}
            </router-link>
          </li>
          <li>
            <a
              class="text-white"
              title="FlixLatino Blog"
              :href="`https://flixlatino.com/blog/${
                $i18n.locale !== undefined ? $i18n.locale : 'es'
              }`"
            >
              {{ $t("footer.news") }}
            </a>
          </li>
        </ul>
      </div>
      <div>
        <ul>
          <li>
            <a
              class="text-white"
              target="_blank"
              :href="$t('trending.landing')"
              :title="$t('Trending')"
            >
              <strong>{{ $t("Trending") }}</strong>
            </a>
          </li>
          <li>
            <a
              class="text-white"
              target="_blank"
              :href="$t('trending.top_movies')"
              :title="$t('Top Movies in 2024')"
            >
              {{ $t("Top Movies in 2024") }}
            </a>
          </li>
          <li>
            <a
              class="text-white"
              target="_blank"
              :href="$t('trending.top_movies_now')"
              :title="$t('Top Movies Right Now')"
            >
              {{ $t("Top Movies Right Now") }}
            </a>
          </li>
          <li>
            <a
              class="text-white"
              target="_blank"
              :href="$t('trending.top_tv_shows')"
              :title="$t('Top TV Shows in 2024')"
            >
              {{ $t("Top TV Shows in 2024") }}
            </a>
          </li>
          <li>
            <a
              class="text-white"
              target="_blank"
              :href="$t('trending.top_tv_shows_now')"
              :title="$t('Top TV Shows Right Now')"
            >
              {{ $t("Top TV Shows Right Now") }}
            </a>
          </li>
        </ul>
      </div>
      <div>
        <ul>
          <li>
            <strong
              ><a
                class="text-white"
                href="https://help.flixlatino.com/hc/es/requests/new"
                >{{ $t("footer.contactUs") }}</a
              ></strong
            >
          </li>
          <li>
            <a class="text-white" :href="$t('routes.faq')">
              {{ $t("footer.faq") }}
            </a>
          </li>
          <li>
            <router-link class="text-white" :to="$t('routes.about_us')">
              {{ $t("footer.aboutUs") }}
            </router-link>
          </li>
        </ul>
      </div>
      <div>
        <ul>
          <li>
            <strong>
              <router-link class="text-white" :to="$t('routes.privacy_policy')">
                {{ $t("footer.privacyPolicy") }}
              </router-link></strong
            >
          </li>
          <li>
            <router-link
              class="text-white"
              :to="$t('routes.terms_and_conditions')"
            >
              {{ $t("footer.termsAndConditions") }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="mt-2">
        <span>{{ $t("footer.callUs") }}</span>
        <a class="phone-number" href="tel://+1-888-533-8395">+1-888-533-8395</a>
        <br /><br />
        <div class="social-media d-flex justify-content-center">
          <a href="https://www.facebook.com/flixlatino" title="Facebook">
            <icon-facebook />
          </a>
          <a href="https://www.instagram.com/flixlatino/" title="Instagram">
            <icon-instagram />
          </a>
          <a href="https://twitter.com/FlixLatino" title="twitter">
            <icon-twitter />
          </a>
        </div>

        <switch-language-component class="w-100 pb-5" />
      </div>
    </div>
    <div class="row copyright justify-content-center">
      <div class="col-auto">
        <span class="text-white">{{ copyright_text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import FlixLatinoLogo from "../icons/flixLatinoLogo.vue";
import IconFacebook from "../icons/iconFacebook.vue";
import IconInstagram from "../icons/iconInstagram.vue";
import IconTwitter from "../icons/iconTwitter.vue";
import SwitchLanguageComponent from "../forms/SwitchLanguageComponent.vue";
import { mapState } from "pinia";
import { UserStore } from "@/stores/userStore";
export default {
  name: "FooterComponent",
  components: {
    SwitchLanguageComponent,
    IconTwitter,
    IconInstagram,
    IconFacebook,
    FlixLatinoLogo,
  },
  computed: {
    ...mapState(UserStore, ["isLoggedIn"]),
    copyright_text: () => {
      return new Date().getFullYear() + " @ FlixLatino";
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/colors";
.mobile {
  .footer {
    background: black;
    margin-top: 3em;
    .social-media {
      width: 80%;
      margin: 0 auto !important;
    }
  }
}
.footer {
  position: relative;
  .links-container {
    column-gap: 2.5rem;
    row-gap: 1.2rem;
  }
  .top-divider {
    position: absolute;
    top: -1px;
    background: url("@/assets/img/divider-line.png") no-repeat top center;
    background-size: contain;
    width: 100%;
    height: 2px;
    margin: 0;
  }
  background: $footer-gradient;
  margin-bottom: 0;
  padding-top: 4em;
  a {
    color: $red;
  }
  li {
    padding: 0.5em 0;
    font-weight: normal;
    strong {
      font-weight: bold;
    }
  }
  .phone-number {
    padding: 1em;
  }
  .social-media {
    a {
      padding: 1.2rem;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
      &:after {
        content: "";
        width: 1px;
        background: linear-gradient(
          0deg,
          rgba(102, 146, 171, 0) 0%,
          #6692ab 54.17%,
          rgba(102, 146, 171, 0) 100%
        );
        height: 1.8em;
        margin-left: 1em;
        display: block;
        float: right;
      }
      &:last-child:after {
        content: none;
      }
    }
  }
  .copyright {
    padding: 3rem 0;
    border-top: 1px solid rgba($color: #fff, $alpha: 0.3);
    span {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }
  @media (max-width: 767px) {
    text-align: center;
  }
}
</style>
